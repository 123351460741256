import {
  Link,
  Links,
  Meta,
  type MetaFunction,
  Outlet,
  Scripts,
  ScrollRestoration,
  useLocation,
  useRouteError,
} from "@remix-run/react";
import { useEffect } from "react";
import * as gtag from "~/lib/gtag.client";
import { AdobeFont } from "./components/adobe-font";
import { Footer } from "./components/footer";
import { Header } from "./components/header";
import { Button } from "./components/ui/button";
import "./globals.css";

export const meta: MetaFunction = () => {
  return [
    { title: "satto | 仕事はみんなでサッと済ませよう" },
    {
      name: "description",
      content:
        "satto | あらゆるツールと繋がって、サッとこなすAIエージェント。どこで何の作業をしていてもサッと登場し、文字起こしやスケジュール調整など日頃の業務をアシストします。sattoを使って、日頃の作業を効率化し早く仕事を終えましょう。",
    },
    {
      name: "og:title",
      content: "satto",
    },
    {
      property: "og:description",
      content:
        "satto | あらゆるツールと繋がって、サッとこなすAIエージェント。どこで何の作業をしていてもサッと登場し、文字起こしやスケジュール調整など日頃の業務をアシストします。sattoを使って、日頃の作業を効率化し早く仕事を終えましょう。",
    },
    {
      property: "og:site_name",
      content: "satto | 仕事はみんなでサッと済ませよう",
    },
    {
      property: "og:url",
      content: "satto.me",
    },
    {
      property: "og:image",
      content: `${import.meta.env.VITE_SITE_URL}/assets/ogp.png`,
    },
    {
      property: "og:locale",
      content: "ja_JP",
    },
    {
      property: "og:type",
      content: "website",
    },
    {
      name: "twitter:card",
      content: "summary_large_image",
    },
    {
      name: "twitter:image",
      content: `${import.meta.env.VITE_SITE_URL}/assets/ogp.png`,
    },
    {
      rel: "apple-touch-icon",
      sizes: "180x180",
      href: "/assets/favicon/apple-touch-icon.png",
    },
    {
      rel: "icon",
      type: "image/x-icon",
      href: "/favicon.ico",
    },
    {
      rel: "icon",
      type: "image/png",
      sizes: "32x32",
      href: "/favicon.ico",
    },
    {
      rel: "manifest",
      href: "/assets/favicon/site.webmanifest",
    },
  ];
};

const gaTrackingId = import.meta.env.VITE_GOOGLE_TAG_ID;
const gtmId = import.meta.env.VITE_GOOGLE_GTM_ID;

export function Layout({ children }: { children: React.ReactNode }) {
  const location = useLocation();
  // biome-ignore lint/correctness/useExhaustiveDependencies: <explanation>
  useEffect(() => {
    if (gaTrackingId?.length) {
      gtag.pageview(location.pathname, gaTrackingId);
    }
  }, [location, gaTrackingId]);

  // biome-ignore lint/correctness/useExhaustiveDependencies: <explanation>
  useEffect(() => {
    addGtmScript(gtmId);
  }, [gtmId]);

  return (
    <html lang="ja">
      <head>
        <meta charSet="utf-8" />
        <meta
          name="viewport"
          content="width=device-width, initial-scale=1, minimum-scale=1, maximum-scale=1, user-scalable=no"
        />
        <Meta />
        <Links />
      </head>
      <body className="overflow-x-hidden">
        <AdobeFont />
        <noscript>
          <iframe
            src={`https://www.googletagmanager.com/ns.html?id=${gtmId}`}
            height="0"
            width="0"
            style={{ display: "none", visibility: "hidden" }}
            title="gtm"
          />
        </noscript>
        {/* 横スクロール発生防止 */}
        {import.meta.env.DEV || !gaTrackingId ? null : (
          <>
            <script
              async
              src={`https://www.googletagmanager.com/gtag/js?id=${gaTrackingId}`}
            />
            <script
              async
              id="gtag-init"
              // biome-ignore lint/security/noDangerouslySetInnerHtml: <explanation>
              dangerouslySetInnerHTML={{
                __html: `
                window.dataLayer = window.dataLayer || [];
                function gtag(){dataLayer.push(arguments);}
                gtag('js', new Date());

                gtag('config', '${gaTrackingId}', {
                  page_path: window.location.pathname,
                });
              `,
              }}
            />
          </>
        )}

        {children}
        <ScrollRestoration />
        <Scripts />
      </body>
    </html>
  );
}

declare global {
  interface Window {
    // biome-ignore lint/suspicious/noExplicitAny: <explanation>
    dataLayer?: any[];
  }
}

export default function App() {
  return <Outlet />;
}

export function ErrorBoundary() {
  const error = useRouteError();

  console.error(error);

  return (
    <div className="grid min-h-screen grid-rows-[auto_1fr_auto]">
      <Header />
      <main>
        <div>
          <div>
            <div className="py-10">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="79"
                height="81"
                viewBox="0 0 79 81"
                fill="none"
                className="mx-auto"
              >
                <path
                  d="M64.6542 6.02331L68.0195 1.98209"
                  stroke="#161615"
                  strokeWidth="3"
                  strokeLinecap="round"
                />
                <path
                  d="M72.1366 15.2846L76.9958 13.3604"
                  stroke="#161615"
                  strokeWidth="3"
                  strokeLinecap="round"
                />
                <path
                  d="M53.0964 63.8497C49.9598 67.132 47.4863 68.8114 42.9649 66.8651C39.9407 65.5633 36.533 62.4088 36.5262 58.8534C36.5116 51.1808 39.4408 42.2702 46.0736 37.7331C50.7503 34.534 56.8993 37.2024 60.4799 40.7191C63.2161 43.4065 63.9632 46.8561 63.1136 50.5945C61.5574 57.4417 54.5717 58.751 48.8017 57.3299C41.2877 55.4792 33.3509 50.3062 28.8993 43.8053C24.9137 37.9851 26.7987 32.5141 30.9517 27.1391C33.761 23.5033 37.9988 18.5388 42.7195 17.7516C46.7682 17.0764 51.3498 20.0629 53.5116 23.2874C58.4881 30.7102 53.1935 42.0303 50.1888 49.4855C47.4544 56.2702 44.5438 59.8493 37.744 61.5071C30.8855 63.1792 24.4332 62.2123 18.827 57.9654C16.6311 56.302 12.5427 53.8344 12.9009 50.4955C13.134 48.3226 15.3015 46.4129 16.604 44.849C18.5342 42.5312 20.3008 40.212 23.3774 39.6479C26.9604 38.9911 29.6373 41.7809 31.7424 44.2961C33.7764 46.7262 31.8276 49.5679 30.0703 51.7454C26.6222 56.0182 21.4176 55.2031 17.6035 52.3138C12.5641 48.4963 13.9048 39.2419 14.7068 33.7022C15.7232 26.6814 18.8402 19.2874 24.6184 14.7809C30.3157 10.3377 43.5686 17.0838 45.4401 23.4925C46.9545 28.6785 43.8015 36.4023 40.9823 40.7502C39.3271 43.303 36.3055 46.1276 33.1295 46.3105C31.3161 46.415 28.8813 44.316 28.8777 42.4415C28.8751 41.0505 29.7579 39.3976 30.4993 38.2799C31.9988 36.0195 33.7366 33.8814 35.4513 31.7886C36.6616 30.3114 38.8011 26.9085 40.936 26.4342C46.373 25.2264 52.3411 32.768 55.62 35.946C58.8295 39.0566 62.1663 40.6052 65.3581 43.74C68.4205 46.7478 53.2155 63.7293 48.5251 61.3548C44.6048 59.3701 61.473 62.0732 62.163 59.1615C80.0939 42.7977 62.5641 44.2147 71.09 34.0064"
                  stroke="#161615"
                  strokeWidth="3"
                  strokeLinecap="round"
                />
              </svg>
            </div>
            <p className="mb-10 text-center font-medium text-[15px] text-dark leading-[180%] md:text-[17px]">
              エラーが発生したようです。
            </p>

            <div className="text-center">
              <Button
                className="h-[52px] w-[319px]"
                variant="secondary"
                asChild
              >
                <Link to="/">ホームへ戻る</Link>
              </Button>
            </div>
          </div>
        </div>
      </main>
      <Footer />
    </div>
  );
}

let gtmScriptAdded = false;

declare global {
  interface Window {
    [key: string]: object[];
  }
}

function addGtmScript(GTM_ID: string) {
  if (!GTM_ID || gtmScriptAdded) {
    return;
  }

  // Code copied from GTM console + added type annotations.
  ((w: Window, d: Document, s: "script", l: string, i: string) => {
    w[l] = w[l] || [];
    w[l].push({
      "gtm.start": new Date().getTime(),
      event: "gtm.js",
    });
    const f = d.getElementsByTagName(s)[0];
    const j = d.createElement<"script">(s);
    const dl = l !== "dataLayer" ? `&l=${l}` : "";
    j.async = true;
    j.src = `https://www.googletagmanager.com/gtm.js?id=${i}${dl}`;
    f.parentNode?.insertBefore(j, f);
  })(window, document, "script", "dataLayer", GTM_ID);

  gtmScriptAdded = true;
}
